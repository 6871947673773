<template>
  <div>
    <div
      v-if="organizationBranding"
      class="h-full flex items-center justify-center relative"
    >
      <img
        :src="organizationBranding.logoUrl"
        class="h-full"
        :key="lastChange"
      />
      <div class="upload-over-logo">
        <Upload :before-upload="handleUpload" action="" accept="image/*">
          <Button class="mt-3" :loading="loading">
            <fa icon="upload" />
            {{ $t("REPLACE") }}
          </Button>
        </Upload>
      </div>
    </div>
    <div
      v-else
      class="text-gray-400 h-full flex flex-col items-center justify-center"
    >
      <fa icon="images" size="6x" />
      <Upload :before-upload="handleUpload" action="" accept="image/*">
        <Button class="mt-3" :loading="loading">
          <fa icon="upload" />
          {{ $t("ADD") }}
        </Button>
      </Upload>
    </div>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  inject: ["organization"],
  data: () => ({ loading: false, lastChange: Date.now() }),
  computed: {
    organizationBranding() {
      const organizationBranding = this.organization.brandings.find(
        ({ serviceName }) => serviceName === "organization"
      );

      return organizationBranding;
    }
  },
  methods: {
    handleUpload(image) {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: gql`
            mutation($tenantId: ID!, $image: Upload!) {
              uploadServiceLogo(
                tenantId: $tenantId
                serviceName: "organization"
                image: $image
              ) {
                id
                serviceName
                logoUrl
              }
            }
          `,
          variables: { tenantId: this.organization.id, image }
        })
        .then(({ data: { uploadServiceLogo } }) => {
          const { id, logoUrl, ...rest } = uploadServiceLogo;
          const brandingIndex = this.organization.brandings.findIndex(
            branding => branding.id === id
          );

          if (brandingIndex > -1) {
            this.organization.brandings[brandingIndex].logoUrl = logoUrl;
          } else {
            this.organization.brandings.push({ id, logoUrl, ...rest });
          }
          // Used as key to force image rerender even if the URL is the same
          this.lastChange = Date.now();
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() => (this.loading = false));

      return false;
    }
  }
};
</script>

<style scoped>
.upload-over-logo {
  @apply absolute flex items-center justify-center opacity-0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.upload-over-logo:hover {
  @apply opacity-100;
}
</style>
