<template>
  <Card :to="to">
    <div class="flex flex-col items-center">
      <fa :icon="icon" size="8x" />
      <br />
      <div class="text-center mt-2 lowercase">
        <Tag :color="value ? 'blue' : 'red'" class="font-bold">
          {{ value }}
        </Tag>
        {{ name }}
      </div>
    </div>
  </Card>
</template>

<script>
export default {
  props: { value: Number, to: [Object, String], name: String, icon: String }
};
</script>

<style scoped></style>
