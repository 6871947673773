<template>
  <Form
    v-if="editing"
    inline
    @submit.native.prevent="updateDisplayName"
    class="flex items-center"
    :disabled="loading"
  >
    <FormItem :error="error" class="m-0 w-64">
      <Input v-model="value" />
    </FormItem>
    <Button
      type="text"
      html-type="submit"
      size="small"
      :disabled="value === displayName"
      :loading="loading"
    >
      <fa icon="save" />
    </Button>
    <Button type="text" size="small" @click="reset">
      <fa icon="times" />
    </Button>
  </Form>
  <div v-else>
    <span class="text-4xl font-bold pr-1 leading-normal">
      {{ displayName }}
    </span>
    <Button type="text" size="small" class="align-super" @click="edit">
      <fa icon="pen" />
    </Button>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  inject: ["organization"],
  data() {
    const { displayName, name } = this.organization;

    return {
      editing: false,
      loading: false,
      error: null,
      value: displayName || name
    };
  },
  computed: {
    displayName() {
      const { displayName, name } = this.organization;

      return displayName || name;
    }
  },
  methods: {
    edit() {
      this.reset();
      this.editing = true;
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
    updateDisplayName() {
      this.loading = true;
      const { value: newDisplayName } = this;
      const { id } = this.organization;

      this.$apollo
        .mutate({
          mutation: gql`
            mutation($id: ID!, $newDisplayName: String!) {
              displayNameRenameTenant(
                id: $id
                newDisplayName: $newDisplayName
              ) {
                id
                displayName
              }
            }
          `,
          variables: { id, newDisplayName }
        })
        .then(
          ({
            data: {
              displayNameRenameTenant: { displayName }
            }
          }) => (this.organization.displayName = displayName)
        )
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(this.reset);
    }
  }
};
</script>

<style scoped></style>
