<template>
  <div class="py-6 xl:px-32">
    <Card dis-hover class="mb-8">
      <div class="flex items-center">
        <ImageUploader class="mr-5 h-56 w-56" v-bind="organization" />
        <div class="flex-grow">
          <DisplayNameEditor class="mb-1" v-bind="organization" />
          <div class="my-2">
            <div class="font-serif text-gray-500 lowercase small-caps">
              {{ $t("NAME") }}
            </div>
            <span class="text-base pr-1 py-1">{{ organization.name }}</span>
            <CopyToClipboardButton
              :value="organization.name"
              class="align-baseline"
            />
          </div>
          <div class="my-2">
            <div class="font-serif text-gray-500 small-caps">
              {{ $t("ID") }}
            </div>
            <span class="text-base pr-1 py-1">{{ organization.id }}</span>
            <CopyToClipboardButton
              :value="organization.id"
              class="align-baseline"
            />
          </div>
          <div class="my-2">
            <div class="font-serif text-gray-500 lowercase small-caps">
              {{ $t("LAST_LOGIN") }}
            </div>
            <LastLogin :date="lastLogin" class="text-base pr-1 py-1" />
          </div>
        </div>
        <div>
          <Button
            @click="teleportMeHere"
            :disabled="AmIHere"
            :loading="loading"
            class="mx-2"
          >
            <fa :icon="['far', 'transporter-2']" class="mr-1"></fa>
            Teleport me here!
          </Button>
        </div>
      </div>
    </Card>
    <div class="widget-grid">
      <StatWidget
        v-for="{ key, ...rest } in stats"
        v-bind="rest"
        :key="key"
        :name="$t(key)"
      />
      <Card :to="{ name: 'organizationQuotasTab' }">
        <Quotas :quotas="organization.quotas" />
      </Card>
    </div>
  </div>
</template>

<script>
import StatWidget from "./components/StatWidget/StatWidget.vue";
import CopyToClipboardButton from "@/components/CopyToClipboardButton.vue";
import DisplayNameEditor from "./components/DisplayNameEditor/DisplayNameEditor.vue";
import ImageUploader from "./components/ImageUploader/ImageUploader.vue";
import Quotas from "@/Organizations/Organizations/components/OrganizationCard/components/Quotas/Quotas.vue";
import LastLogin from "@/components/LastLogin/LastLogin.vue";
import { getLastUserLogin } from "@/Organizations/Organizations/components/OrganizationCard/helpers.js";
import userMeQuery from "./queries/userMe.gql";
import switchTenantQuery from "./queries/switchTenant.gql";
import tenantQuery from "@/Organizations/Organization/queries/tenant.gql";

export default {
  components: {
    StatWidget,
    Quotas,
    LastLogin,
    CopyToClipboardButton,
    DisplayNameEditor,
    ImageUploader
  },
  data: () => ({ loading: false }),
  inject: ["organization"],
  apollo: { userMe: { query: userMeQuery, update: ({ userMe }) => userMe } },
  computed: {
    stats: ({ organization: { users = [], roles = [], qlikApps = [] } }) => [
      {
        key: "USERS",
        to: { name: "organizationUsersTab" },
        value: users.length,
        icon: "users"
      },
      {
        key: "ROLES",
        to: { name: "organizationRolesTab" },
        value: roles.length,
        icon: "tags"
      },
      {
        key: "QLIK_APPLICATIONS",
        to: { name: "organizationQlikApplicationsTab" },
        value: qlikApps.length,
        icon: "database"
      }
    ],
    lastLogin: ({ organization: { users } }) => getLastUserLogin(users),
    AmIHere: ({ userMe: { roles = [] } = {}, organization }) =>
      !!roles.find(({ tenant: { id } }) => id === organization.id)
  },
  methods: {
    teleportMeHere() {
      this.loading = true;

      this.$apollo
        .mutate({
          mutation: switchTenantQuery,
          variables: { id: this.userMe.id, tenantId: this.organization.id },
          refetchQueries: [
            { query: tenantQuery, variables: { id: this.organization.id } }
          ]
        })
        .then(() =>
          this.$Notice.info({
            title: "to infinity and beyond!",
            duration: 1.5
          })
        )
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("OUPS_SOMETHING_WENT_WRONG"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped>
.widget-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
  grid-gap: 2rem;
  justify-content: space-between;
}
</style>
